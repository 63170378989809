.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
  gap: 20px;
  // margin-bottom: 20px;
  width: 100%;
}

.progress-bar__text {
  font-family: 'Montserrat';
font-weight: 700;
font-size: 20px;
color: #fff;
// margin-top: 35px;
  margin-bottom: 10px;
  // font-size: 16px;
  // font-weight: bold;
}

.progress-bar {
  width: 100%;
  height: 7px;
  background-color: #e0e0e0;
  // border-radius: 0px;
  overflow: hidden;
}

.progress-bar__fill {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}
