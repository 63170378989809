.start-screen {
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center; // Центрируем содержимое горизонтально
  // height: calc(100vh - 100px); // Высота контейнера равна высоте экрана
  gap: 40px;
  margin-top: 80px;
  padding: 0;
  text-align: center;
  color: #fff;
  height: 100%;
  // padding: 20px; // Увеличиваем внутренний отступ для мобильных экранов
  box-sizing: border-box; // Учитываем отступы в расчете размеров

  &__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 71px; // Используем относительные единицы для адаптации
    line-height: 71px;
    color: #fff;
    text-align: left;
    // margin-bottom: 20px; // Добавляем отступ под текст
    margin:0;
  }

  &__lead {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px; // Используем относительные единицы для адаптации
    line-height: 20px;
    color: #fff;
    text-align: left;
    // margin-bottom: 20px; // Добавляем отступ под текст
  }

  &__button {
    // position: fixed;
    // bottom: 70px;
    // max-width: 330px;
    // width:100%;
    // margin-bottom: 100px;
    width:185px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px; // Относительная единица для адаптации
    color: #272727;
    border-radius: 20px;
    // width: 100%;
    // max-width: 400px; // Ограничиваем ширину кнопки
    padding: 15px 0;
    border:0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: background 0.3s ease;

    &:hover {
      background: #1cc;
    }
  }

  // @media (max-width: 767px) {
  //   &__title {
  //     font-size: 2rem; // Уменьшаем размер текста для маленьких экранов
  //     margin-bottom: 10px;
  //   }

  //   &__button {
  //     font-size: 1rem;
  //     padding: 10px;
  //     max-width: 250px; // Уменьшаем максимальную ширину кнопки
  //   }
  // }
}

.btn_Wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-evenly;
}

