.quiz-wrapper {
  position: relative;
  // display: flex;
  // flex-direction: column; justify-content: space-between; align-items: center; display: inline-flex;
  width: 100%;
  // height: calc(100vh - 100px);
  // top: 100px;
  height: 100vh;

  max-width: 330px;
    margin: 0 auto;

  .background-video {
    position: fixed; /* Видео фиксируется на экране */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабируем видео для заполнения экрана */
    z-index: -1; /* Перемещаем видео на задний план */
    filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='20' result='b'/%3E%3CfeMorphology operator='dilate' radius='40'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");

  }

  #c {
    position: fixed; /* Видео фиксируется на экране */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабируем видео для заполнения экрана */
    z-index: -1; /* Перемещаем видео на задний план */
    // display: block;
  }
  
  .quiz-container {
    position: relative;
    z-index: 1; /* Элементы поверх видео */
    max-width: 330px;
    margin: 30px 0 auto;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat';
    height: 100%; /* Растягиваем контейнер на высоту экрана */
    display: flex; /* Центрируем содержимое */
    flex-direction: column;
    justify-content:flex-start; /* Центрируем вертикально */
    align-items: center; /* Центрируем горизонтально */
  }
}


.mobile-quiz {
  height: calc(100vh - 60px);
  top: 60px;
}

