.question {
  max-width: 330px;
  margin: 0 auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 300px;
  box-sizing: border-box;
  border-radius: 20px;

  background: linear-gradient(153.43deg, rgb(27, 24, 102) 6.153%, rgb(43, 40, 124) 77.285%);
  padding: 20px;

  .modal-image {
    // margin-bottom: 20px; /* Уменьшаем отступ для изображения */
    img {
      width: 100%;
      border-radius: 10px;
      // height: 190px;
      // border-radius: 50px;
    }
  }

  .modal-image-source {
    font-size: 10px;
    margin: 0;
    padding: 0;
    text-align: right;
    opacity: 0.5;
  }
  

  p {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: left;
    white-space: pre-wrap;
  }
}

.modal-btn {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  color: #272727;
  border-radius: 20px;
  width: 100%;
  padding: 15px 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border:0;
  cursor: pointer;
  margin: 20px auto;
  max-width: 300px; /* Ограничиваем ширину кнопки */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: #1cc;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.optionsg {
  // border: 2px solid transparent;
  padding: 10px;
  // margin: 10px 0;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &.correct {
    // border-color: green;
    background-color: #13d140 !important;
    color: #155724; /* Темно-зеленый текст */
    cursor: default; /* Убирает "палец" */
  }

  &.incorrect {
    // border-color: red;
    background-color: #e86c77 !important; /* Светло-красный фон */
    color: #721c24; /* Темно-красный текст */
    cursor: default; /* Убирает "палец" */
  }

  &.inactive {
    background-color: #e2e3e5 !important; /* Серый фон */
    color: #7f8183; /* Серый текст */
    // border-color: #545455; /* Серые границы */
    cursor: not-allowed; /* Отключение нажатий */
  }
}

.single-choice {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  // height: 100vh;
  height: 100%;
  padding: 0;
  // padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;

  .title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-align: left;
    margin-bottom: 20px;
    word-wrap: break-word;
    flex-grow: 1;
  }

  .text {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 10px;
    width: 100%
  }

  .optionssg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    justify-content: center;
    // align-content: baseline;
    align-content: center;
    width: 100%;
    max-width: 330px;
    margin-bottom: 100px;

    .optionsg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 120px;
      // aspect-ratio: 1 / 1;
      border-radius: 15px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 400;
      color: #272727;
      cursor: pointer;
      max-height: 155px;
      transition:
        background 0.3s ease,
        transform 0.2s ease;

      // &:hover {
      //   background: #e0f7fa;
      //   transform: scale(1.05);
      // }
    }

    .circlesg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #11cccc;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 10px;
    }
  }

//   @media (max-width: 767px) {
//     .title {
//       font-size: 18px;
//     }

//     .text {
//       font-size: 14px;
//     }
//   }

//   @media (max-width: 478px) {
//     .title {
//       font-size: 16px;
//     }

//     .text {
//       font-size: 12px;
//     }
//   }
}
