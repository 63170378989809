$result-bg-gradient: linear-gradient(180deg, #2b0058 0%, #3a007d 100%);
$result-accent: #56f89a;
$result-light: #ffffff;
$result-dark: #2b0058;

.container {
  max-width: 400px;
  margin: 0 auto;
  color: $result-light;
  font-family: "Montserrat", sans-serif;
  height: 100%; /* Устанавливаем высоту контейнера в 100% экрана */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Равномерно распределяем пространство */
  overflow: hidden; /* Убираем прокрутку */
}

.header-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  // padding-top: 10px;
}

.result-screen {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:start; /* Центрируем все элементы по вертикали */
  flex-grow: 1; /* Позволяет растянуть контейнер по высоте */
  width: 100%; /* Занимаем всю ширину */
  box-sizing: border-box; /* Чтобы padding и border не увеличивали размеры */

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    // margin-top: 20px;

    &__title {
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $result-accent;
      letter-spacing: 2px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 30px;
    padding: 30px 0px 10px;
    width: 100%;
    max-width: 400px; /* Максимальная ширина */
    height: auto;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: #dff4fa;
    background-image: url('pattern.svg');
    // gap: 10px;
    margin-bottom: 10px; /* Добавляем отступ снизу */

    &__trophy {
      font-size: 6rem;
      height: 100px;
      width:100px;
      margin-bottom: 1rem;
      background-image: url('cup.png');
    }

    &__score {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 48px;
      text-align: center;
      color: #1cc;
    }

    &__message {
      font-size: 1.2rem;
      margin: 0.5rem 0;

      &__highlight {
        color: $result-accent;
        font-family: "Courier New", monospace;
        font-size: 1.25rem;
        text-transform: uppercase;
      }
    }

    &__stat {
      font-size: 0.9rem;
      color: rgba($result-light, 0.8);
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__button {
      margin-top: 20px;

      &--share {
        background-color: #0088cc;

        &:hover {
          background-color: darken(#0088cc, 10%);
        }
      }

      &--download {
        background-color: $result-accent;

        &:hover {
          background-color: darken($result-accent, 10%);
        }
      }

      &__icon {
        width: 20px;
        height: auto;
        margin-right: 0.5rem;
      }
    }
  }
}
.trophy {
  // font-size: 6rem;
  height: 100px;
  width:100px;
  margin-bottom: 1rem;
  background-image: url('cup.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.score {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #1cc;
  margin: 0;
}

.message {
  font-family: var(--font-family);
  font-weight: 700;
  padding: 0 20px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #353535;
  margin: 0;
}

.highlight {
  font-family: var(--third-family);
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #1cc;
  padding: 0;
}

.message_title {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #353535;
  margin: 0 0 20px;
}
.message_desc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #353535;
}

.stat {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #353535;
}

.submit-button {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  color: #272727;
  border-radius: 20px;
  // width: 330px;
  padding: 15px 20px;
  border: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  cursor: pointer;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: #1cc;
  }
}

