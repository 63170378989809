@font-face {
  font-family: 'Montserrat';
  src: url('../public/Fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../public/Fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/Fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  line-height: 41px;
}

body {
  margin: 0;
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  user-select: none;
  // overscroll-behavior-y: contain !important; /* Предотвращает перетягивание на iOS */
  height: -webkit-fill-available;
  min-height: 100%;
}


html {
  overflow: hidden;
}

body::-webkit-scrollbar {
  // display: none; /* Скрыть скроллбар в Webkit (например, Safari, Chrome) */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-body {
  overflow: hidden;
  height: 100vh;
}

.mobile-wrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  // overscroll-behavior-y: contain !important;
}

.mobile-content {
  height: calc(100% + 1px);
}
